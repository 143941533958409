define([], function () {

    const ScrollOut = require('scroll-out');

    $(document).ready(function() {

        if ($('.ferrari-cars').length) {

            ScrollOut({
                targets: '.js-video-wrap',
                onShown: function(el) {
                    let $video = $(el).find('iframe'),
                        src = $video.attr('src');
                    $video.attr('src', src + '&autoplay=1');
                },
                onHidden: function(el) {
                    let $video = $(el).find('iframe'),
                        src = $video.attr('src');
                    $video.attr('src', src.replace('&autoplay=1', ''));
                }
            });

        }

    });

});
