define(['jquery'], function ($) {

    const defaultOptions = {};

    const initAutocomplete = function (element, newOptions) {
        require.ensure([], function (require) {
            require('jquery-ui/ui/widgets/autocomplete');

            let options = Object.assign({}, defaultOptions, newOptions);

            element.autocomplete(options);

        }, 'autocomplete');
    };

    module.exports = initAutocomplete;

});