// Accordion
define(['jquery'], function ($) {

    $.fn.isOnScreen = function(){

        var win = $(window);
    
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();
    
        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();
    
        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
    };

    let is_paused = false;

    const mainCarsSelector = $('.bmw-m__main-cars');
    const collectionCarsSelector = $('.bmw-m__collection-cars');
    const promotionCarsSelector = $('.bmw-m__promotions-cars');

    const promotionVideoWrapSelector = $('.bmw-m__video-wrap');
    const promotionVideoSelector = document.getElementById("bmw-m__promotion-video");

    function animatedChampionshipElements() {
        try {
            if(mainCarsSelector.isOnScreen() == true) {
                if(!mainCarsSelector.hasClass('bmw-m__cars-animation')) {
                    setTimeout(function() {
                        mainCarsSelector.addClass('bmw-m__cars-animation')
                    },100)
                    
                }
            } else {
                if(mainCarsSelector.hasClass('bmw-m__cars-animation')) {
                    mainCarsSelector.removeClass('bmw-m__cars-animation')
                }
            }
        } catch (err) {
            console.error(err);
        }

        try {
            if(collectionCarsSelector.isOnScreen() == true) {
                if(!collectionCarsSelector.hasClass('bmw-m__cars-animation')) {
                    setTimeout(function() {
                        collectionCarsSelector.addClass('bmw-m__cars-animation')
                    },100)
                    
                }
            } else {
                if(collectionCarsSelector.hasClass('bmw-m__cars-animation')) {
                    collectionCarsSelector.removeClass('bmw-m__cars-animation')
                }
            }
        } catch (err) {
            console.error(err);
        }

        try {
            if(promotionCarsSelector.isOnScreen() == true) {
                if(!promotionCarsSelector.hasClass('bmw-m__cars-animation')) {
                    setTimeout(function() {
                        promotionCarsSelector.addClass('bmw-m__cars-animation')
                    },100)
                    
                }
            } else {
                if(promotionCarsSelector.hasClass('bmw-m__cars-animation')) {
                    promotionCarsSelector.removeClass('bmw-m__cars-animation')
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    function autoplayBmwVideo() {
        try {
            if(promotionVideoWrapSelector.isOnScreen() == true && !is_paused) {
                promotionVideoSelector.play();
                is_paused = true
            } 
        } catch (err) {
            console.error(err);
        }
    }

    if(mainCarsSelector.length) {
        $(window).scroll(function() {
            animatedChampionshipElements();
            autoplayBmwVideo();
        });

        $(document).ready(function() {
            animatedChampionshipElements();
        })
    }

});
