define(['jquery'], function ($) {

    const defaultOptions = {
        dots: true,
        dotsClass: 'focus-slider-dots',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-arrow-prev"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon icon-arrow-next"></i></button>',
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    const slickCarousel = function (slickEl, slickOptions) {
        require.ensure([], function (require) {
            require('slick-carousel');

            let newSlickOptions = Object.assign({}, defaultOptions, slickOptions);

            slickEl.slick(newSlickOptions);

        }, 'slick-carousel');
    };

    module.exports = slickCarousel;

});