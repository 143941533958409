define([], function () {

    $(document).ready(function() {

        const $btnOpenMobFilter = $('.js-open-filters'),
            $filters = $('.js-personal-filters'),
            $btnCloseMobFilters = $('.js-filters-close');

        $btnOpenMobFilter.on('click', function(e) {
            e.preventDefault();

            $filters.show(300);
        });

        $btnCloseMobFilters.on('click', function(e) {
            e.preventDefault();

            $filters.hide(300);
        });



        $(document).on('click', '.js-filter-apply', function(e){
            e.preventDefault();

            let form = $(this).parents('form');
            let wrapper = $(this).parents('.js-filter-wrapper');
            let data = form.serializeArray();

            form.find('.js-selectmulti').each(function(){
                let name = $(this).data('name');
                $(this).find('.js-options-list .selected').each(function(){
                    data.push({name, value: $(this).data('value')});
                });
            });

            wrapper.addClass('loader');

            $.ajax({
                method: form.attr('method'),
                url: form.attr('action'),
                data: data,
                dataType: 'json',
                success: function(data){
                    wrapper.find('.js-filter-receiver').html(data.html);
                    if ($btnCloseMobFilters.is(':visible')) {
                        $filters.hide(300);
                    }

                    wrapper.removeClass('loader');
                }
            });

        });

    });

});
