define([], function () {

    $(document).ready(function() {

        const $jsBtnFixedGoTop = $('.js-btn-fixed-go-top'),
            $header = $('.js-main-header');

        $('.js-go-to-anchor').on('click', function(e) {
            e.preventDefault();

            let anchor = $(this).attr("href");

            $('html, body').animate({
                scrollTop: $(anchor).offset().top - $header.outerHeight()
            }, 300);
        });

        $('.js-go-top').on('click touchend', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: 0
            }, 300);

            return false;

            // window.scrollTo(0, 0);

        })

        $(window).on('scroll', function () {
            initBtnFixedGoTop();
        })

        initBtnFixedGoTop();

        function initBtnFixedGoTop() {
            if ( $(window).scrollTop() > $(window).outerHeight() / 2 ) {
                $jsBtnFixedGoTop.removeClass('hide');
            } else {
                $jsBtnFixedGoTop.addClass('hide');
            }
        }

    });

});
