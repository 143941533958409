define([], function () {

    (function init100vh(){

        function setHeight() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setHeight();

        // Bug on mobile devices when auxiliary browser panels appear/disappear.
        // window.addEventListener('resize', setHeight);

        window.addEventListener("orientationchange", function() {
            setTimeout(setHeight, 300);
        }, false);

    })();

});