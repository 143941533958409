(function ($) {

    $(document).ready(function() {

        const $mainHeader = $('.js-main-header'),
            $btnOpenSearch = $('.js-open-search'),
            $btnOpenSearchMob = $('.js-open-search-mob'),
            $btnCloseSearch = $('.js-close-search'),
            $burger = $('.js-burger'),
            $nav = $('.js-header-nav'),
            $bottomBlock = $('.js-header-bottom'),
            $search = $('.js-header-search'),
            $btnNextSection = $('.js-go-to-id-header');

        $btnOpenSearch.on('click', function(e) {
            e.preventDefault();
            $mainHeader.addClass('open-search');
        });

        $btnCloseSearch.on('click', function(e) {
            e.preventDefault();
            $mainHeader.removeClass('open-search');
        });

        $burger.on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass("selected");

            if ( $(this).hasClass("selected") ) {
                $nav.slideDown();
            } else {
                $nav.slideUp();
                $search.slideUp();
            }

        });

        $btnOpenSearchMob.on('click', function(e) {
            e.preventDefault();
            $burger.addClass("selected");
            $nav.slideUp(function() {
                $search.slideDown();
            });
        });

        $btnNextSection.on('click', function(e) {
            e.preventDefault();

            let anchor = $(this).attr("href");

            $burger.removeClass("selected");
            $nav.slideUp(function() {

                $('html, body').animate({
                    scrollTop: $(anchor).offset().top
                }, 300);

            });
        });

    });

}(jQuery));
