define([], function () {

    const slickCarousel = require('./../modules/slick-carousel');

    $(document).ready(function() {

        $('.js-slider-offers').each(function() {

            slickCarousel($(this), {
                autoplay: true,
                adaptiveHeight: true,
            });

        });

        $('.js-slider-social-img').each(function(){
            slickCarousel($(this), {
                dots: false,
                slidesToShow: 3,
                prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-arrow-prev-v1"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="icon icon-arrow-next-v1"></i></button>',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                ]
            });
        });

    });

});
