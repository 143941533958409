define([], function () {

    const ScrollOut = require('scroll-out');

    $(document).ready(function() {

        if ($('.fortune').length) {

            ScrollOut({
                targets: '.js-animate-text-left',
                onShown: function(el) {
                    setTimeout(() => el.classList.add('text-block_animated'), 500);
                },
                onHidden: function(el) {
                    el.classList.remove('text-block_animated')
                }
            });

            ScrollOut({
                targets: '.fortune__step',
                onShown: function(el) {
                    setTimeout(() => el.classList.add('fortune__step_animated'), 500);
                },
                onHidden: function(el) {
                    el.classList.remove('fortune__step_animated')
                }
            });

            ScrollOut({
                targets: '.js-animate-text-right',
                onShown: function(el) {
                    setTimeout(() => el.classList.add('super-gift-content_animated'), 500);
                },
                onHidden: function(el) {
                    el.classList.remove('super-gift-content_animated')
                }
            });

            ScrollOut({
                targets: '.js-animate-gift',
                onShown: function(el) {
                    setTimeout(() => el.classList.add('fortune__gifts-item_animated'), 500);
                },
                onHidden: function(el) {
                    el.classList.remove('fortune__gifts-item_animated')
                }
            });

            // создаём переменные для быстрого доступа ко всем объектам на странице — блоку в целом, колесу, кнопке и язычку
            const wrapper = document.querySelector(".js-casino-table");
            const spinner = wrapper.querySelector(".js-dynamic-table");

            // прописываем CSS-классы, которые будем добавлять и убирать из стилей
            const spinClass = "is-spinning";

            // переменная для анимации
            let tickerAnim;
            // угол вращения
            let rotation = 0;

            // определяем количество оборотов, которое сделает наше колесо
            const spinertia = (min, max) => {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
            };

            // функция запуска вращения с плавной остановкой
            const runTickerAnimation = () => {
                // запускаем анимацию
                tickerAnim = requestAnimationFrame(runTickerAnimation);
            };

            // отслеживаем нажатие на кнопку
            function createAnimation() {
                // задаём начальное вращение колеса
                rotation = Math.floor(/*Math.random()*/spinertia(2, 4) * 360/* + spinertia(100, 800)*/);
                // добавляем колесу класс is-spinning, с помощью которого реализуем нужную отрисовку
                wrapper.classList.add(spinClass);
                // через CSS говорим секторам, как им повернуться
                spinner.style.setProperty("--rotate", rotation);
                // запускаем анимацию вращение
                runTickerAnimation();
            }

            // отслеживаем, когда закончилась анимация вращения колеса
            spinner.addEventListener("transitionend", () => {
                // останавливаем отрисовку вращения
                cancelAnimationFrame(tickerAnim);
                // получаем текущее значение поворота колеса
                rotation %= 360;
                // выбираем приз
                // selectPrize();
                // убираем класс, который отвечает за вращение
                wrapper.classList.remove(spinClass);
                // отправляем в CSS новое положение поворота колеса
                spinner.style.setProperty("--rotate", rotation);
            });

            // запускаем анимацияю в первый раз
            createAnimation();

            // запускаем анимацию каждые 9,5 секунд
            setInterval(createAnimation, 9500);

            $('.js-show-all-winners').on('click', function (e) {
                e.preventDefault();
                $(this).parent().toggleClass('less');
                $('.js-winners-table').toggleClass('showing');
            })

        }

    });

});
