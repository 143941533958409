define([], function () {

    require('@chenfengyuan/datepicker');

    $(document).ready(function() {

        const $inputDateFrom = $('#date-from'),
              $inputDateTo = $('#date-to'),
              $triggerDatepicker = $('.js-trigger-datapicker');

        function closeAllSelectAndDatepickers(element) {
            const $selects = $('.select-multi');
            for(let i = 0; i < $selects.length; i++) {
                if($($selects[i]).hasClass('open')) {
                    $($selects[i]).removeClass('open');
                    $($selects[i]).children('.js-dropdown').slideUp()
                }
            }
            if(element) {
                element.datepicker('hide');
            }
        }

        $inputDateFrom.datepicker({
            language: 'uk-UA',
            format: 'dd-mm-yyyy',
            autoPick: false,
            autoHide: true,
            // trigger: '.js-trigger-datapicker',
        });

        $inputDateTo.datepicker({
            language: 'uk-UA',
            format: 'dd-mm-yyyy',
            autoPick: false,
            autoHide: true
        });

        $triggerDatepicker.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).siblings('input').datepicker('show');
        });

        $($inputDateFrom).on('show.datepicker', function (e) {
            closeAllSelectAndDatepickers($inputDateTo)
        });

        $($inputDateTo).on('show.datepicker', function (e) {
            closeAllSelectAndDatepickers($inputDateFrom)
        });
    });

});
