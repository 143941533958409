define([], function () {
    const {getCookie, setCookie} = require("./../modules/cookies");

    if(!getCookie('is_cookie_confirm')) {
        let $browserCookieDisabledPanel = $('.browser-cookie-disabled'),
            $cookieConfirmPanel = $('.cookie-confirm');

        if (!navigator.cookieEnabled) {
            $browserCookieDisabledPanel.css('display', 'block');
        } else {
            $cookieConfirmPanel.css('display', 'block');
        }
    }

    $(document).ready(function() {
        $('.cookie-confirm .confirm, .cookie-confirm .decline').on('click', function(e){
            e.preventDefault();

            setCookie('is_cookie_confirm', 1, {expires: 360 * 24 * 60 * 60});

            $(this).parents('.cookie-confirm').remove();
        });
    });
});
