define([], function () {

    const {getCookie, setCookie} = require("./../modules/cookies");

    $(document).ready(function() {

        const $btnOpenSubNav = $('.js-open-sub-nav'),
              $btnOpenMenu = $('.js-open-personal-menu'),
              $btnCloseMenu = $('.js-sidebar-close'),
              $personalMenu = $('.js-personal-cabinet-sidebar');

        $btnOpenSubNav.on('click', function(e) {
            e.preventDefault();

            $(this).parent().toggleClass('open-sub-nav');
            $(this).toggleClass('open');
        });

        $btnOpenMenu.on('click', function(e) {
            e.preventDefault();

            if(!$personalMenu.hasClass('personal-cabinet__sidebar--active')) {
                $personalMenu.addClass('personal-cabinet__sidebar--active')
            }
            
        })

        $btnCloseMenu.on('click', function(e) {
            e.preventDefault();

            if($personalMenu.hasClass('personal-cabinet__sidebar--active')) {
                $personalMenu.removeClass('personal-cabinet__sidebar--active')
            }

            $(this).closest('.personal-cabinet').removeClass('personal-cabinet__show-menu');

        })

        function showPersonalMenu() {
            if ( !getCookie("first-login-personal-cabinet") ) {
                $('.personal-cabinet').addClass('personal-cabinet__show-menu');
                setCookie("first-login-personal-cabinet", true);
            }
        }

        if ($personalMenu.length > 0)
            showPersonalMenu();

    });

});
