define([], function () {

    $(document).ready(function() {

        $(document).on('click', '.js-play-video', function(e) {
            e.preventDefault();

            let $wrapper = $(this).closest('.js-video-wrap'),
                $video = $wrapper.find('iframe'),
                src = $video.attr('src');

            $video.attr('src', src + '&autoplay=1');
            $wrapper.addClass('video-active');

            if ($wrapper.find('video')) {
                $loadedVideo = $wrapper.find('video');
                $loadedVideo[0].play();
                $loadedVideo[0].setAttribute('controls','');
            }
        });


    });

});
