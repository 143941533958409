// Accordion
define(['jquery'], function ($) {

    $.fn.isOnScreen = function(){

        var win = $(window);
    
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();
    
        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();
    
        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
    };

    const ballBangElement = $('.championship__details--bang');
    const countBangElement = $('.championship__details-refuel-count');

    function animatedChampionshipElements() {
        try {
            if(ballBangElement.isOnScreen() == true) {
                if(!ballBangElement.hasClass('championship__details--bang-animated')) {
                    setTimeout(function() {
                        ballBangElement.addClass('championship__details--bang-animated')
                    },100)
                    
                }
            } else {
                if(ballBangElement.hasClass('championship__details--bang-animated')) {
                    ballBangElement.removeClass('championship__details--bang-animated')
                }
            }
        } catch (err) {
            console.error(err);
        }

        try {
            if(countBangElement.isOnScreen() == true) {
                if(!countBangElement.hasClass('championship__details-refuel-count--animated')) {
                    setTimeout(function() {
                        countBangElement.addClass('championship__details-refuel-count--animated')
                    },100)
                    
                }
            } else {
                if(countBangElement.hasClass('championship__details-refuel-count--animated')) {
                    countBangElement.removeClass('championship__details-refuel-count--animated')
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    if(ballBangElement.length) {
        $(window).scroll(function() {
            animatedChampionshipElements();
        });

        $(document).ready(function() {
            animatedChampionshipElements();
        })
    }

    const previewVideoElement = $('.championship__video-preview');
    const youtubeVideoElement = $('.championship__video-youtube');

    previewVideoElement.on('click', function(e) {
        previewVideoElement.hide();

        youtubeVideoElement[0].src += "?rel=0&autoplay=1";
        e.preventDefault();
    })

});
