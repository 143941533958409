require("./bootstrap");

// jQuery parts
require("./parts-jquery/_init");
require("./parts-jquery/_input-style");
require("./parts-jquery/_header");
require("./parts-jquery/_sliders");
require("./parts-jquery/_go-to-anchors");
require("./parts-jquery/_mask-input");
require("./parts-jquery/_authorization");
require("./parts-jquery/_personal-menu");
require("./parts-jquery/_vh-init");
require("./parts-jquery/_select-multi");
require("./parts-jquery/_select-bootstrap");
require("./parts-jquery/_popup-tables");
require("./parts-jquery/_popup-user-data");
require("./parts-jquery/_datapicker");
require("./parts-jquery/_filters");
require("./parts-jquery/_get-more");
require("./parts-jquery/_video-handlers");
require("./parts-jquery/_swipers");
require("./parts-jquery/_validation");
require("./parts-jquery/_accordion");
require("./parts-jquery/_counter");
require("./parts-jquery/_buy-certificate");
require("./parts-jquery/_basket-certificate");
require("./parts-jquery/_cookie-confirm");
require("./parts-jquery/_input-upload-file");
require("./parts-jquery/_input-upload-file-v1");
require("./parts-jquery/_popup");
require("./parts-jquery/_autocomplete");
require("./parts-jquery/_championship");
require("./parts-jquery/_navigator-filters");
require("./parts-jquery/_certificate-popup");
require("./parts-jquery/_scroll-out");
require("./parts-jquery/_transaction");
require("./parts-jquery/_prmotion_ferrari-toy-cars");
require("./parts-jquery/_promotion_fortune");
require("./parts-jquery/_bmw-m");
require("./parts-jquery/_ferrari-promo");
require("./parts-jquery/_million-reasons");
// Modules
require("./modules/youtube.js");

//require('./navigator');
