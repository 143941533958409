// Select Multi Custom

// Data Attributes
// data-search="true/false" - Атрибут добавляется к родительскому елементу (.js-selectmulti). Активирует поиск по списку options (фильтрация осуществляется по тексту в option).
// data-value="value, value, value" - Атрибут добавляется к кнопке (.js-btn). Можно использовать для показа выбранных option по умолчанию (при загрузке страницы). Значение в масиве должны совпадать с data-value="value" атрибута option.

// Events
// selectmulti:open
// selectmulti:close
// selectmulti:change

define(["jquery"], function ($) {
    const List = require("list.js");

    let selectmulti = function (selectWrapper, userSettings) {
        selectWrapper.each(function () {
            const _this = $(this),
                classList = {
                    selectBtn: ".js-btn",
                    selectDropdown: ".js-dropdown",
                    selectResultList: ".js-result-lists",
                    selectOptionsList: ".js-options-list",
                    selectOptionsListSelected: ".js-options-list-selected",
                    selectOption: ".js-options-list > li",
                    valueText: ".js-value-text",
                    btnApply: ".js-apply",
                    btnClear: ".js-clear",
                    search: ".js-search",
                    listSelected: ".js-options-list-selected",
                };

            let settings = {
                    optionShowing: 10, // height options list
                    search: false, // search by text options
                },
                optionsList = [],
                optionsListActive = [],
                optionsListBackup = [];

            let listOptionsSetting = {
                    listClass: "select-multi__option-list",
                    searchClass: "select-multi__search-input",
                    valueNames: ["name", { data: ["value"] }],
                    item: '<li class="select-multi__option" data-value=""><span class="name"></span></li>',
                },
                listOptionsSelectedSetting = {
                    listClass: "select-multi__option-list-selected",
                    valueNames: ["name", { data: ["value"] }],
                    item: '<li class="select-multi__option selected" data-value=""><span class="name"></span></li>',
                },
                listOptions,
                listOptionsSelected;

            const searchTemplate = `<div class="select-multi__search js-search">
                    <input class="select-multi__search-input search" />
                    <i class="icon icon-magnifying-glass"></i>
                </div>`,
                optionsSelectedWrap = `<div class="js-options-list-selected">
                    <ul class="select-multi__option-list-selected js-options-list"></ul>
                 </div>`;

            let methods = {
                init: function () {
                    $.extend(settings, userSettings);

                    this.getAllOptions();
                    this.checkBtnState();
                    this.initSearch();
                    this.updateOptions(optionsListActive);

                    _this.on("click", function (e) {
                        e.stopPropagation();
                    });

                    _this.on("click", classList.selectBtn, function (e) {
                        e.preventDefault();
                        methods.toggleOptionsList();
                    });

                    _this.on("click", classList.selectOption, function (e) {
                        e.preventDefault();
                        methods.selectOption($(this));
                    });

                    _this.on("click", classList.btnApply, function (e) {
                        e.preventDefault();
                        methods.changeSelect();
                    });

                    _this.on("click", classList.btnClear, function (e) {
                        e.preventDefault();
                        methods.clearSelect();
                    });

                    $("body").on("click", function (e) {
                        methods.cancelSelect();
                    });
                },

                checkBtnState: function () {
                    const { selectBtn } = this.getElements();
                    let value = selectBtn.data("value");

                    if (value) {
                        _this.addClass("filled");
                        let valArr = String(value)
                            .replace(/\s+/g, "")
                            .split(",");

                        optionsListActive = valArr.slice();
                        optionsListBackup = valArr.slice();

                        this.updateOptions(optionsListActive);
                    } else {
                        _this.removeClass("filled");
                    }
                },

                getAllOptions: function () {
                    let { selectOption } = this.getElements();
                    selectOption.each(function (index, item) {
                        optionsList.push({
                            name: $(item).text(),
                            id: $(item).data("value"),
                        });
                    });
                },

                toggleOptionsList: function () {
                    let { selectDropdown } = this.getElements();

                    if (!_this.hasClass("open")) {
                        selectDropdown.slideDown();
                        _this.addClass("open").trigger("selectmulti:open");
                        optionsListBackup = optionsListActive.slice();
                    } else {
                        selectDropdown.slideUp();
                        _this.removeClass("open").trigger("selectmulti:close");
                    }
                },

                selectOption: function ($option) {
                    let optionValue = $option.data("value");

                    if (!$option.hasClass("selected")) {
                        $option.addClass("selected");
                        optionsListActive.push(optionValue);
                    } else {
                        $option.removeClass("selected");

                        let i = optionsListActive.indexOf(optionValue);
                        if (i >= 0) optionsListActive.splice(i, 1);
                    }
                },

                changeSelect: function () {
                    const { selectBtn, selectDropdown, valueText } =
                        this.getElements();

                    if (optionsListActive.length > 0) {
                        _this.addClass("filled");
                    } else {
                        valueText.html("");
                        _this.removeClass("filled");
                    }

                    selectBtn.data("value", optionsListActive);
                    selectBtn.attr("data-value", optionsListActive);
                    selectDropdown.slideUp();
                    _this.removeClass("open");
                    _this.trigger("selectmulti:change", [optionsListActive]);

                    this.updateOptions(optionsListActive);
                },

                cancelSelect: function () {
                    const { selectBtn, selectDropdown, valueText } =
                        this.getElements();

                    if (optionsListBackup.length > 0) {
                        selectBtn.data("value", optionsListBackup);
                        selectDropdown.slideUp();

                        _this.addClass("filled").removeClass("open");
                    } else {
                        selectDropdown.slideUp();
                        valueText.html("");
                        _this.removeClass("filled").removeClass("open");
                    }

                    this.updateOptions(optionsListBackup);
                },

                clearSelect: function () {
                    const {
                        selectBtn,
                        selectDropdown,
                        selectOption,
                        valueText,
                    } = this.getElements();

                    _this.removeClass("open filled");
                    selectDropdown.slideUp();
                    selectBtn.data("value", "").attr("data-value", "");
                    optionsListActive = [];
                    optionsListBackup = [];
                    valueText.html("");
                    selectOption.removeClass("selected");

                    this.updateOptions(optionsListActive);

                    _this.trigger("selectmulti:change", [optionsListActive]);
                },

                updateOptions: function (optionsListNew) {
                    optionsListActive = optionsListNew.slice();
                    optionsListBackup = optionsListNew.slice();

                    this.updateBtnValueCount(optionsListNew);
                    this.clearSearch();

                    if (listOptions) {
                        this.updateLists(optionsListNew);
                    } else {
                        this.createList(optionsListNew);
                    }

                    const { selectOption } = this.getElements();
                    selectOption.removeClass("selected");
                    optionsListNew.forEach(function (item) {
                        selectOption
                            .filter(`[data-value="${item}"]`)
                            .addClass("selected");
                    });
                },

                initSearch: function () {
                    const { selectDropdown } = this.getElements();
                    let searchAttr = _this.data("search");

                    if (searchAttr) settings.search = true;

                    if (settings.search) {
                        selectDropdown.prepend(searchTemplate);
                        _this.addClass("search-active");
                    }
                },

                clearSearch: function () {
                    const { search } = this.getElements();
                    search.find("input").val("");

                    if (listOptions) listOptions.search();
                },

                createList: function () {
                    // Создание основного списка опций и его сортировка по алфафиту
                    listOptions = new List(_this[0], listOptionsSetting);
                    //listOptions.sort('name', { order: "asc" });
                    if (optionsListActive.length > 0) {
                        this.createListSelected();

                        // Удаляем из списка уже выбранные опции
                        optionsListActive.forEach(function (item) {
                            listOptions.remove("value", item);
                        });
                    }
                },

                createListSelected: function () {
                    const { selectResultList } = this.getElements();

                    // Добавление обвертки для выбранных опций в HTML
                    selectResultList.prepend(optionsSelectedWrap);

                    // Создание списка выбранных опций и вывод списка в селект
                    if (!listOptionsSelected) {
                        listOptionsSelected = new List(
                            selectResultList.find(classList.listSelected)[0],
                            listOptionsSelectedSetting
                        );
                    }
                },

                updateLists: function (optionsListNew) {
                    this.resetLists();

                    if (optionsListNew.length > 0 && !listOptionsSelected) {
                        this.createListSelected();
                    }

                    optionsListNew.forEach(function (item) {
                        optionsList.forEach(function (_opt) {
                            if (_opt.id === item) {
                                listOptionsSelected.add({
                                    name: _opt.name,
                                    value: _opt.id,
                                });
                                listOptions.remove("value", _opt.id);
                            }
                        });
                        // listOptionsSelected.add({
                        //     name: optionsList[item - 1].name,
                        //     value: optionsList[item - 1].id,
                        // });
                        // listOptions.remove("value", optionsList[item - 1].id);
                    });
                    //listOptions.sort("name", { order: "asc" });
                },

                resetLists: function () {
                    if (listOptionsSelected) listOptionsSelected.clear();
                    listOptions.clear();
                    for (var i = 0; i < optionsList.length; i++) {
                        listOptions.add({
                            name: optionsList[i].name,
                            value: optionsList[i].id,
                        });
                    }
                    // for (let obj in optionsList) {
                    //     listOptions.add({
                    //         name: optionsList[key],
                    //         value: key,
                    //     });
                    // }
                },

                updateBtnValueCount: function (optionsListNew) {
                    const { valueText } = this.getElements();

                    let optionCount =
                        optionsListNew.length >= 1
                            ? `<span class="count">${optionsListNew.length}</span>`
                            : "";

                    valueText.html(optionCount);
                },

                getElements: function () {
                    return {
                        selectBtn: _this.find(".js-btn") || "",
                        selectDropdown: _this.find(".js-dropdown") || "",
                        selectResultList: _this.find(".js-result-lists") || "",
                        selectOptionsList: _this.find(".js-options-list") || "",
                        selectOptionsListSelected:
                            _this.find(".js-options-list-selected") || "",
                        selectOption: _this.find(".js-options-list > li") || "",
                        valueText: _this.find(".js-value-text") || "",
                        btnApply: _this.find(".js-apply") || "",
                        btnClear: _this.find(".js-clear") || "",
                        search: _this.find(".js-search") || "",
                    };
                },
            };

            return methods.init();
        });
    };

    $(document).ready(function () {
        selectmulti($(".js-selectmulti"));

        function closeAllSelectAndDatepickers($datepicker, $select = null) {
            const $selects = $(".js-selectmulti");
            for (let i = 0; i < $selects.length; i++) {
                // debugger
                if ($($selects[i]).hasClass("open") && $selects[i] != $select) {
                    $($selects[i]).removeClass("open");
                    $($selects[i]).children(".js-dropdown").slideUp();
                    // select.slideDown();
                }
            }
            if ($datepicker) {
                $datepicker.datepicker("hide");
            }
        }

        $(".js-selectmulti").on("selectmulti:open", function (e) {
            const $inputDateFrom = $("#date-from"),
                $inputDateTo = $("#date-to");

            closeAllSelectAndDatepickers($inputDateFrom, e.target);
            closeAllSelectAndDatepickers($inputDateTo, e.target);
        });

        // $('.js-selectmulti').on('selectmulti:close', function(e) {
        //     console.log('trigger event --- ', 'selectmulti:close');
        // })

        // $('.js-selectmulti').on('selectmulti:change', function(e, value) {
        //     console.log('trigger event --- ', 'selectmulti:change');
        //     console.log('e --- ', e);
        //     console.log('value --- ', value);
        // })

        $(".js-filter-date").on("click", function (e) {
            e.preventDefault();
            selectmulti.init();
        });

        // $('.js-test-select').on('click', function(){
        //     console.log('================= --- ', 'click');
        //
        //     $('.js-selectmulti').each(function () {
        //         console.log(' --- ', $(this).find('.js-btn').data('value'));
        //     });
        //
        // });

        $(".js-remove-filters").on("click", function (e) {
            e.preventDefault();

            $(".js-selectmulti").each(function () {
                $(this).find(".js-clear").trigger("click");
            });
        });
    });
});
