// Accordion
define(['jquery'], function ($) {

    let accordion = function (accordionEl, userSettings) {
        accordionEl.each(function () {
            let _this = $(this);
            let settings = {
                speed: 400, // animation speed
                oneOpen: true, // close all other accordion items if true
                firstOpen: false, // open/close first accordion item
            };
            let prevHeight = 0,
                prevIndex = 0;

            let methods = {

                init: function () {

                    let blocks = this.getElements();

                    this.addClass();

                    $.extend(settings, userSettings);

                    if (settings.firstOpen && blocks.accordionItem.length > 1) {
                        $(blocks.accordionItem)
                            .eq(0)
                            .addClass('open')
                            .children('.accordion__body')
                            .slideDown();
                    }

                    blocks.accordionHeader.on('click', function (e) {
                        e.preventDefault();

                        methods.toggle($(this));

                    });

                },

                toggle: function ($this) {

                    let blocks = this.getElements(),
                        thisIndex = blocks.accordionItem.index($this.closest('.accordion__item'));

                    if (settings.oneOpen && !$this.closest('.accordion__item').hasClass('open')) {
                        $this.closest('.accordion')
                            .find('> .accordion__item')
                            .removeClass('open')
                            .find('> .accordion__body')
                            .slideUp();
                    }

                    $this.closest('.accordion__item').toggleClass('open');
                    $this.next('.accordion__body').slideToggle(settings.speed, function () {
                        if ($this.closest('.accordion__item').hasClass('open')) {
                            prevHeight = $this.next('.accordion__body').outerHeight();
                        } else {
                            prevHeight = 0;
                        }
                        prevIndex = thisIndex;
                    });

                    let windowScrollTop = $('html, body').scrollTop() || window.pageYOffset;

                    if (prevIndex < thisIndex) {
                        $('html, body').animate({
                            scrollTop: windowScrollTop - prevHeight
                        }, settings.speed);
                    }

                },

                addClass: function () {
                    let blocks = this.getElements();

                    blocks.accordionItem.each(function (index, element) {

                        if (!$(element).hasClass('accordion__item')) {
                            $(element).addClass('accordion__item');
                        }

                    });

                    blocks.accordionHeader.each(function (index, element) {

                        if (!$(element).hasClass('accordion__head')) {
                            $(element).addClass('accordion__head');
                        }

                    });

                    blocks.accordionBody.each(function (index, element) {

                        if (!$(element).hasClass('accordion__body')) {
                            $(element).addClass('accordion__body');
                        }

                    });
                },

                getElements: function () {
                    return {
                        accordionItem: _this.children() || '',
                        accordionHeader: _this.children().find('> *:first-child') || '',
                        accordionBody: _this.children().find('> *:last-child') || ''
                    };

                }

            };


            return methods.init();
        });

    };

    $(document).ready(function () {
        accordion($('.js-accordion'));
    });

});
