import "../../../../node_modules/inputmask/dist/jquery.inputmask";

$(document).ready(function () {
    let $inputsPhone = $(".js-mask-phone"),
        $inputsCard = $(".js-mask-card"),
        $inputsDate = $(".js-mask-date"),
        $smsPass = $(".js-mask-sms-code"),
        $inputsDigits = $(".js-mask-digits");

    $inputsPhone.inputmask({
        mask: "+38(099)999-99-99",
        showMaskOnHover: false,
    });
    $inputsCard.inputmask({
        mask: "7004[9]{1,13}",
        showMaskOnHover: false,
    });
    $inputsDate.inputmask({
        mask: "99-99-9999",
        showMaskOnHover: false,
    });
    $smsPass.inputmask({
        mask: "9999",
        showMaskOnHover: false,
    });

    $inputsDigits.inputmask({ regex: "\\d*" });
});
