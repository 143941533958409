define([], function () {

    $(document).ready(function() {

        let $buyForm = $('.js-form-buy-certificate'),
            $inputs = $buyForm.find('input'),
            $btnSubmit = $('.js-btn-submit'),
            $priceTypeSwitchersWrap = $('.js-price-type-switchers');

        // Отслеживание изменения в форме и контроль за состоянием кнопки отправки формы
        $inputs.on('change', function() {
            checkBtnSubmit();
        });

        // Подсветка выбора цены в соответствии с номиналом
        $inputs.filter('[data-denomination]').on('change', function() {

            let value = $(this).val();

            $priceTypeSwitchersWrap.find('input[type="radio"]').each(function() {
                changeDisabled($(this), true);
                changeChecked($(this), false);
            });

            $priceTypeSwitchersWrap.find(`input[data-price-type="${value}"]`).each(function() {
                changeDisabled($(this), false);
            });

        });

        // Проверка полей формы на заполненность
        function validInputs() {

            if ( !$inputs.filter('[data-denomination]:checked').length )
                return false;

            let denominationVal = $inputs.filter('[data-denomination]:checked').val();

            if ( !$inputs.filter(`[data-price-type="${denominationVal}"]:checked`).length )
                return false;

            if ( $inputs.filter('[name="certificate-num"]').val() <= 0 )
                return false;

            // if ( !$inputs.filter('[name="agree"]').is(':checked') )
            //     return false;

            return true;

        };

        // Изменение состояния кнопки отправки формы
        function checkBtnSubmit() {

            const disabledBtn = validInputs();

            if (disabledBtn) {
                changeDisabled($btnSubmit, false);
            } else {
                changeDisabled($btnSubmit, true);
            }

        }

        function changeDisabled($elem, state) {
            $elem.attr("disabled", state);
            $elem.prop("disabled", state);
        }

        function changeChecked($elem, state) {
            $elem.attr("checked", state);
            $elem.prop("checked", state);
        }

    });

});
