define(['jquery'], function ($) {

    const defaultOptions = {};

    module.exports = function (swiperEl, swiperOptions) {

        return require.ensure([], function (require) {

            let Swiper = require('swiper/swiper-bundle.min');

            let newSwiperOptions = Object.assign({}, defaultOptions, swiperOptions);

            return new Swiper(swiperEl, newSwiperOptions);

        }, 'swiper');

    };

});