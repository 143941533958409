define([], function () {

    $(document).ready(function() {

        const $btnOpenInfo = $('.js-open-user-data'),
            $popupUserData = $('.js-popup-user-data'),
            $popupClose = $('.js-popup-close'),
            $btnConfirmDeleteUserData = $('.js-confirm-delete-user-data'),
            $popupConfirmContent = $('.popup-user-data__content--confirm'),
            $popupConfirmedContent = $('.popup-user-data__content--confirmed'),
            $confirmCheckbox = $('#shellsmart-club');

        if(window.success_deleted){
            $popupUserData.show(300);
            $popupConfirmContent.hide();
            $popupConfirmedContent.show(300);
        }

        $confirmCheckbox.on('click', function(e) {
            var isChecked = checkConfirmShellsmart();

            if(isChecked) {
                $btnOpenInfo.attr('disabled', false);
            }
            else {
                $btnOpenInfo.attr('disabled', true);
            }
        })

        $btnOpenInfo.on('click', function(e) {
            e.preventDefault();
            $popupUserData.show(300);
        });

        $popupClose.on('click', function(e) {
            e.preventDefault();
            $popupUserData.hide(300);
        });

        $btnConfirmDeleteUserData.on('click', function(e) {
            $('#profile_delete_confirm').submit();
            // $popupConfirmContent.hide(300);
            // $popupConfirmedContent.show(300);
        });



        function checkConfirmShellsmart () {
            if($confirmCheckbox.is(":checked")) {
                return true
            }
            else {
                return false
            }
        }

    });

});


