define([], function () {

    const ScrollOut = require('scroll-out');

    $(document).ready(function() {

        ScrollOut({
            targets: '.shell-cafe__gift-item--1',
            onShown: function(el) {
                setTimeout(() => el.classList.add('shell-cafe__gift-item--animated'), 500);
            },
            onHidden: function(el) {
                el.classList.remove('shell-cafe__gift-item--animated')
            }
        })

        ScrollOut({
            targets: '.shell-cafe__gift-item--2',
            onShown: function(el) {
                setTimeout(() => el.classList.add('shell-cafe__gift-item--animated'), 700);
            },
            onHidden: function(el) {
                el.classList.remove('shell-cafe__gift-item--animated')
            }
        })

        ScrollOut({
            targets: '.shell-cafe__gift-item--3',
            onShown: function(el) {
                setTimeout(() => el.classList.add('shell-cafe__gift-item--animated'), 1000);
            },
            onHidden: function(el) {
                el.classList.remove('shell-cafe__gift-item--animated')
            }
        })

        ScrollOut({
            targets: '.shell-cafe__gift-item--4',
            onShown: function(el) {
                setTimeout(() => el.classList.add('shell-cafe__gift-item--animated'), 1200);
            },
            onHidden: function(el) {
                el.classList.remove('shell-cafe__gift-item--animated')
            }
        })

        ScrollOut({
            targets: '.shell-cafe__gift-large',
            onShown: function(el) {
                setTimeout(() => el.classList.add('shell-cafe__gift-large--animated'), 750);
            },
            onHidden: function(el) {
                el.classList.remove('shell-cafe__gift-large--animated')
            }
        })
        
    });

});
