define([], function () {

    $(document).ready(function() {

        const $popup = $('.js-popup'),
            $popupClose = $('.js-popup-close');

        $popupClose.on('click', function(e) {
            e.preventDefault();
            $(this).closest('.js-popup').hide();
        });

        if ($('.js-popup-timeout').length) {
            setTimeout(function () {
                $('.js-popup-timeout').hide();
            }, 3000);
        }

    });

});
