define([], function () {

    const selectBootstrap = require('./../modules/select-bootstrap');

    $(document).ready(function() {

        $('.js-select-bootstrap').each(function() {

            selectBootstrap($(this));

        });

    });

});
