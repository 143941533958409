define(["jquery"], function ($) {
    $(document).ready(function () {
        $(document).on("click", ".js-get-more", function (e) {
            e.preventDefault();

            let a = $(this);
            let wrapper = $(e.target).parents(".js-get-more-wrapper");

            wrapper.addClass("loader");

            $.ajax({
                url: $(this).attr("href"),
                method: "get",
                dataSource: "json",
                success: function (data) {
                    wrapper.find(".js-get-more-receiver").append(data.html);
                    if (data.next_page) {
                        a.attr("href", data.next_page);
                    } else {
                        a.hide();
                        a.attr("href", a.data("base-url"));
                        a.parents(".btns-wrap").find(".js-get-less").show();
                    }

                    wrapper.removeClass("loader");
                },
            });
        });
        $(document).on("click", ".js-get-less", function (e) {
            e.preventDefault();
            let a = $(this);

            $(".js-get-more-receiver > div").slice(a.data("items")).remove();

            a.hide();
            a.parents(".btns-wrap").find(".js-get-more").show();
        });
    });
});
