define(['jquery'], function(){

    let initFileUploads = function(){

        // const inputsStyle = require("./input-style");
        // const mask = require("../modules/inputmask");
        // const initSelectpicker = require("./select-bootstrap");
        // const {addLoader, removeLoader} = require('./loader');

        const MEDIA_REGEX = new RegExp("(.*?)\(image|video)");
        const DOC_REGEX = new RegExp("(.*?)\.(pdf|xls|xlsx|doc|docx)$");
        const MAX_SIZE_MEDIA_FILE = 10; // Mb
        const MAX_SIZE_DOC_FILE = 10; // Mb
        const $closeBtn = '<a href="" class="field-close js-remove-field"><i class="icons icon-close"></i></a>';

        let $wraper = $('.js-clone-field'),
            $wraperBlock = $('.js-clone-block'),
            $linkCloneInput = $('.js-link-clone-field'),
            $linkCloneSelect = $('.js-link-clone-select'),
            $linkCloneBlock = $('.js-link-clone-block');

        // $linkCloneInput.on('click', function(e) {
        //     e.preventDefault();
        //
        //     let $originalEL = $(this).closest('.js-clone-field').find('.form-group').last(),
        //         $newInput = createInputClone($originalEL);
        //
        //     $newInput.append($closeBtn);
        //     $newInput.insertBefore($(this));
        //
        //     inputsStyle($newInput.find('input'));
        //
        //     if ( $newInput.find('.js-mask-international').length ) {
        //         mask($newInput.find('.js-mask-international'), 'phone');
        //     } else if ( $newInput.find('.js-mask-site').length ) {
        //         mask($newInput.find('.js-mask-site'), 'site');
        //     }
        //
        // });

        // $linkCloneSelect.on('click', function(e) {
        //     e.preventDefault();
        //
        //     let $parent = $(this).closest('.js-clone-field'),
        //         $selectClone = $parent.find('.form-group').last().find('select').clone(),
        //         $labelClone = $parent.find('.form-group').last().find('label').clone(),
        //         name = $selectClone.attr('name'),
        //         newName = renderNewName(name);
        //
        //     $selectClone.attr("name", newName).removeAttr("tabindex").removeClass('is-invalid');
        //     $selectClone.val("").find('option:selected').removeAttr("selected");
        //     $selectClone.find('option.bs-title-option').remove();
        //
        //     $selectClone.insertBefore($(this));
        //
        //     if ( $parent.find('.input-inset-label').length ) {
        //         $selectClone.wrapAll("<div class='form-group input-inset-label'></div>");
        //     } else {
        //         $selectClone.wrapAll("<div class='form-group'></div>");
        //     }
        //
        //     $selectClone.after($labelClone);
        //     $labelClone.after($closeBtn);
        //
        //     initSelectpicker($selectClone);
        //
        // });

        $wraper.on('change', 'input[type=file]', function (e) {

            console.log('change --- ', 'input[type=file]');

            let val = e.target.files[0],
                name = val.name,
                size = val.size,
                type = val.type,
                $label = $(e.target).next('label'),
                sending = true,
                $wrapperClones = $(this).closest('.js-clone-field'),
                $newInput = createInputClone($wrapperClones.find('.form-group').last());

            $newInput.find('.js-remove-field').remove();

            // if($(this).closest('.upload-file.media').length !== 0) {
            //     sending = checkRegexFile(MEDIA_REGEX, type, size, MAX_SIZE_MEDIA_FILE, this);
            // } else if ($(this).closest('.upload-file.doc').length !== 0) {
            //     sending = checkRegexFile(DOC_REGEX, name, size, MAX_SIZE_DOC_FILE, this);
            // } else {
            //     sending = checkRegexFile(DOC_REGEX, name, size, MAX_SIZE_DOC_FILE, this);
            // }

            sending = checkRegexFile(MEDIA_REGEX, type, size, MAX_SIZE_MEDIA_FILE, this);

            if (sending) {
                showFile(e.target, $label);
                // $(this).prop('disabled', true);
                $wrapperClones.append($newInput);
            }

        });

        // $linkCloneBlock.on('click', function(e) {
        //     e.preventDefault();
        //
        //     let $wrapper = $(this).closest('.js-clone-block'),
        //         $clone = $wrapper.find('.js-clone-content').last().clone();
        //
        //     if ( $clone.find('.form-group').length !== 0 ) {
        //         $clone.find('.form-group').each(function(index, item) {
        //             let $newInput = createInputClone($(item));
        //             $clone.find('.form-group').eq(index).replaceWith($newInput);
        //         });
        //     }
        //
        //     $clone.find('.js-remove-field').remove();
        //     $clone.append($closeBtn);
        //     $clone.insertBefore($(this));
        //
        //     $clone.find('input').each(function() {
        //         inputsStyle($(this));
        //     });
        //
        // });

        $wraper.on('click', '.js-remove-field', function(e) {
            e.preventDefault();
            $(this).closest('.form-group').remove();
        });

        // $wraperBlock.on('click', '.js-remove-field', function(e) {
        //     e.preventDefault();
        //     $(this).closest('.js-clone-content').remove();
        // });

        function createInputClone($origilalEl) {

            let $clone = $origilalEl.clone(),
                name = $clone.find('input').attr('name'),
                id = $clone.find('input').attr('id'),
                newName = renderNewName(name),
                newId = renderNewId(id);

            $clone.removeClass('active');
            $clone.find('input').attr("name", newName).attr("id", newId).val("").removeClass('is-invalid');
            $clone.find('label').attr("for", newId);
            $clone.find('.js-remove-field').remove();
            $clone.find('.invalid-feedback').remove();

            return $clone;

        }

        function renderNewName(attrVal) {
            let pattern = /^.+\[(\d+)\].*?$/;
            let lastIndex = attrVal.search(pattern),
                newName = '';

            if ( lastIndex !== -1 ) {
                newName = attrVal.replace(pattern, function(p1, p2){
                    return p1.replace(/^(.+\[)(\d+)(\].*?)$/g, "$1" + (parseInt(p2)+1) + '$3');
                });
            } else {
                let index = 1;
                newName = attrVal + '[' + (index) + ']';
            }

            return newName;
        }

        function renderNewId(attrVal) {

            let pattern = /^.+_(\d+).*?$/;
            let lastIndex = attrVal.search(pattern),
                newId = '';

            if ( lastIndex !== -1 ) {
                newId = attrVal.replace(pattern, function(p1, p2){
                    return p1.replace(/^(.+_)(\d+)(.*?)$/g, "$1" + (parseInt(p2)+1) + '$3');
                });
            } else {
                let index = 1;
                newId = attrVal + '_' + (index);
            }

            return newId;

        }

        function checkRegexFile(regex, val, fileSize, maxSize, target) {
            let helpText = $(target).data('errorText'),
                sizeConvertMb = fileSize/1024/1024;

            if (!helpText) {
                let errorFileAttr = new Error(
                    `Добавьте для input[type'file'] атрибут:
data-error-text='...текст сообщения при неправильном разширении файла...'`
                );
                console.error(errorFileAttr.message);
                return false;
            }

            if (!(regex.test(val)) || sizeConvertMb > maxSize) {
                $(target).val('');
                $(target).parent().append('<span class="invalid-feedback" role="alert"><strong>' + helpText + '</strong></span>');
                setTimeout(function(){
                    $(target).parent().find('.invalid-feedback').remove();
                }, 5000);
                return false;
            }

            return true;
        }

        function showFile(target, $mediaWrapper) {

            console.log('showFile --- ', );

            let reader = new FileReader(),
                val = target.files[0],
                name = val.name,
                type = val.type;

            // if ( /image/i.test(type) || /video/i.test(type) )
            //     addLoader($(target).next('label'));

            reader.onload = function(e) {
                $(target).attr("tabindex", "-1").blur();
                $(target).next('label').removeAttr("for");
                // removeLoader($(target).next('label'));
                // if ( /image/i.test(type) ) {
                //     $mediaWrapper.html(`<span class="locking">${$closeBtn}</span><img src="${e.target.result}" alt="">`);
                // } else if ( /video/i.test(type) ) {
                //     $mediaWrapper.html(`<span class="locking">${$closeBtn}</span><video controls="controls" src="${e.target.result}" poster=""></video>`);
                // } else {
                //     $mediaWrapper.html(`<span class="locking"><a href="" class="field-close js-remove-field">-</a></span>${name}`);
                // }
                $mediaWrapper.html(`<a href="" class="field-close js-remove-field"><i class="icon icon-cancel"></i></a>${name}`);
            };

            reader.readAsDataURL(val);

        }

    }

    $(document).ready(function() {

        initFileUploads();

    });

});
