const openModalBtns = document.querySelectorAll('.btn-default-youtube'),
      iframeModal = document.querySelector('#modal-frame-video'),
      closeModal = document.querySelector('.modal--youtube-close'),
      modal = document.querySelector('.overlay--youtube');
try {
    openModalBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            if(!modal.classList.contains('modal--youtube-open')) {
                let youtubeVideo = e.target.dataset.youtubeKey;
                iframeModal.src= `https://www.youtube.com/embed/${youtubeVideo}`;
                modal.classList.add('modal--youtube-open');
            }
        })
    });
    closeModal.addEventListener('click', () => {
        if(modal.classList.contains('modal--youtube-open')) {
            modal.classList.remove('modal--youtube-open');
            iframeModal.src = '';
        }
    })
}
catch (e) {
    console.log(e);
}
