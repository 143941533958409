import "jquery-validation";

$(document).ready(function() {

    $.validator.addMethod("requiredPhone", function(value, element, param) {
        value = value.replace('+7 (___) ___ - __ - __');

        if ( !this.depend( param, element ) ) {
            return "dependency-mismatch";
        }
        if ( element.nodeName.toLowerCase() === "select" ) {
            var val = $( element ).val();
            return val && val.length > 0;
        }
        if ( this.checkable( element ) ) {
            return this.getLength( value, element ) > 0;
        }
        return $.trim( value ).length > 0;
    }, "Поле, обов'язкове для заповнення");

    $.validator.addMethod("minlengthPhone", function(value, element, param) {
        value = value.replace('(', '');
        value = value.replace(')', '');
        value = value.replace(/-/g, '');
        value = value.replace(/_/g, '');


        var length = $.isArray( value ) ? value.length : this.getLength( $.trim( value ), element );
        return this.optional( element ) || length >= param;
    }, "Введіть номер телефона повністю.");

    $.validator.addMethod(
        /* The value you can use inside the email object in the validator. */
        "regex",

        /* The function that tests a given string against a given regEx. */
        function(value, element, regexp)  {
            /* Check if the value is truthy (avoid null.constructor) & if it's not a RegEx. (Edited: regex --> regexp)*/

            if (regexp && regexp.constructor != RegExp) {
                /* Create a new regular expression using the regex argument. */
                regexp = new RegExp(regexp);
            }

            /* Check whether the argument is global and, if so set its last index to 0. */
            else if (regexp.global) regexp.lastIndex = 0;

            /* Return whether the element is optional or the result of the validation. */
            return this.optional(element) || regexp.test(value);
        }
    );

    jQuery.extend(jQuery.validator.messages, {
        required: "Поле, обов'язкове для заповнення",
        remote: "Corrigeer dit veld.",
        email: "Будь ласка, введіть дійсний email.",
        url: "Voer een geldige URL in.",
        date: "Vul alstublieft een geldige datum in.",
        dateISO: "Voer een geldige datum (ISO) in.",
        number: "Voer alstublieft een geldig nummer in.",
        digits: "Введіть лише цифри.",
        creditcard: "Voer een geldig creditcardnummer in.",
        equalTo: "Voer dezelfde waarde opnieuw in.",
        accept: "Voer een waarde in met een geldige extensie.",
        maxlength: jQuery.validator.format("Voer niet meer dan {0} tekens in."),
        minlength: jQuery.validator.format("Введіть не менше {0} символів."),
        rangelength: jQuery.validator.format("Voer een waarde in tussen {0} en {1} tekens lang."),
        range: jQuery.validator.format("Voer een waarde in tussen {0} en {1}."),
        max: jQuery.validator.format("Voer een waarde in die kleiner is dan of gelijk is aan {0}."),
        min: jQuery.validator.format("Voer een waarde in die groter is dan of gelijk is aan {0}.")
    });

    $("#registration-form").validate({
        errorElement: "span",
        rules: {
            firstName: {
                required: true,
                minlength: 2
            },
            lastName: {
                required: true,
                minlength: 2
            },
            email: {
                required: false,
                email: true,
                regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
            },
            phone: {
                requiredPhone: true,
                minlengthPhone: 13
            },
            card: {
                required: true,
                digits: true
            },
            "g-recaptcha-response": {
                required: true
            },
        },
        messages: {
            email: {
                email: "Будь ласка, введіть дійсний email.",
                regex: "Будь ласка, введіть дійсний email."
            },
            phone: {
                requiredPhone: "Поле, обов'язкове для заповнення.",
                minlengthPhone: "Введіть номер телефона повністю."
            },
            houselet: {
                maxlength: "Enter valid letter."
            },
            cardnumber: {
                minlength: "Please enter all digits of card.",
                maxlength: "Please enter the valid number."
            },
            year: {
                minlength: "Please enter a full year.",
                maxlength: "Please enter the valid year."
            },
            password2: {
                equalTo: "Password is not the same."
            }
        }
    });

    $("#contacts-form").validate({
        errorElement: "span",
        rules: {
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true,
                regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
            },
            phone: {
                requiredPhone: true,
                minlengthPhone: 13
            },
            card: {
                required: false,
                digits: true
            },
            subject: {
                required: true,
                minlength: 6
            },
            message: {
                required: true,
                minlength: 12
            },
            image: {
                required: false,
            }
        },
        messages: {
            email: {
                email: "Будь ласка, введіть дійсний email.",
                regex: "Будь ласка, введіть дійсний email."
            },
            phone: {
                requiredPhone: "Поле, обов'язкове для заповнення.",
                minlengthPhone: "Введіть номер телефона повністю."
            },
            houselet: {
                maxlength: "Enter valid letter."
            },
            cardnumber: {
                minlength: "Please enter all digits of card.",
                maxlength: "Please enter the valid number."
            },
            year: {
                minlength: "Please enter a full year.",
                maxlength: "Please enter the valid year."
            },
            password2: {
                equalTo: "Password is not the same."
            }
        }
    });

});
