define(['jquery'], function ($) {

    const selectBootstrap = function (selectEl) {
        require.ensure([], function (require) {
            require('bootstrap');
            require('bootstrap-select');

            require('ajax-bootstrap-select');
            require('./../templates/ua-locale-select-ajax');
            require('./../templates/en-locale-select-ajax');

            let locale = 'ua-UA';
            switch ($('html').attr('lang')) {
                case 'en':
                    locale = 'en-GB';
                    break;
            }

            let title = (selectEl.attr("title") && selectEl.attr("title").length) > 0 ? selectEl.attr("title") : " ";
                urlAjax = selectEl.data("urlAjax"),
                langSelect = $.fn.ajaxSelectPicker.locale[locale];

            if ( selectEl.val().length > 0 || selectEl.find('option[selected]').length > 0 )
                selectEl.closest('.input-inset-label').addClass('active');

            let options = {
                ajax: {
                    url: urlAjax,
                    type: 'GET',
                    dataType: 'json',
                    data: function() {
                        let params = {
                            search_term: '{{{q}}}'
                        };
                        params.region = window.regionId;
                        return params;
                    },
                },
                minLength: 2,
                preprocessData: function (response) {

                    let arrayOptions = [];

                    response.data.forEach(function(item) {
                        arrayOptions.push($.extend(true, {}, {
                            text : item.name,
                            value: item.code,
                        }));
                    });

                    return arrayOptions;

                },
                preserveSelected: false,
                locale: langSelect,
                cache: false,
            };

            selectEl.selectpicker({
                title: title,
                noneSelectedText: ' ',
                size: 6,
                showContent: false
            }).on('show.bs.select', function (e) {
                $(e.target).closest('.input-inset-label').addClass('active');
            }).on('hide.bs.select', function (e) {
                if ( e.target.value.length === 0 ) {
                    $(e.target).closest('.input-inset-label').removeClass('active');
                }
            }).filter('.with-ajax')
                .ajaxSelectPicker(options);

            selectEl.closest('.input-inset-label').find('label').on('click', function(e) {
                e.preventDefault();

                let parent = $(e.target).closest('.input-inset-label');

                if ( !parent.hasClass('active') )
                    parent.find('select').selectpicker('toggle');

            });

        }, 'bootstrap-select');
    };

    module.exports = selectBootstrap;

});