import axios from "axios";

axios.defaults.headers = {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
};

$(document).ready(function() {

    let authMethod = 'phone';
    const inputWrappers = document.querySelectorAll('.form-group--transaction');

    $('input[name="transaction-method"]').on('change', function() {

        let value = $(this).val();

        inputWrappers.forEach(inp => {
            if(inp.dataset.transactionMethod === value) {
                inp.classList.remove('hidden');
            } else {
                inp.classList.add('hidden');
            }
        })

        authMethod = value;

    });

});
