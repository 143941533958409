define([], function () {

    let loadContent = (url, callback) => {

        $.ajax({
            url,
            dataType: 'json',
            success: function(data){
                callback(data);
            }
        })
    };

    $(document).ready(function() {

        const $btnOpenInfo = $('.js-open-info'),
            $popupTable = $('.js-popup-table-info'),
            $popupClose = $('.js-popup-close');

        $(document).on('click', '.js-open-info', function(e) {
            e.preventDefault();

            $('.js-popup-table-info .success').hide();
            $('.js-popup-table-info .fail').hide();

            $popupTable.show(300);

            loadContent($(this).attr('href'), function(data){
                if(data.status == 1) {
                    $('.js-popup-table-info .success').show();

                    $('.js-popup-table-info .old_quantity').text(data.old_quantity);
                    $('.js-popup-table-info .new_quantity').text(data.new_quantity);
                    $('.js-popup-table-info .expire_at').text(data.expire_at);
                }else if(data.html) {
                    $('.js-popup-table-info .js-popup-content').html(data.html);
                }else{
                    $('.js-popup-table-info .fail').show();
                }
            });
        });

        $popupClose.on('click', function(e) {
            e.preventDefault();
            $popupTable.hide(300);
        });

    });

});
