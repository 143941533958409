define([], function () {
    const swiperCarousel = require("./../modules/swiper");

    $(document).ready(function () {
        try {
            let swiperGasStation = swiperCarousel(
                ".gas-station-winner__gifts-slider",
                {
                    slidesPerView: 5,
                    centeredSlides: false,
                    loop: true,
                    loopedSlides: 7,
                    autoplay: {
                        delay: 3000,
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1280: {
                            slidesPerView: 4,
                        },
                        1600: {
                            slidesPerView: 5,
                        },
                    },
                }
            ).then((swiperObj) => {
                const arrowSliderGasStationPrev = document.querySelector(
                    ".gas-station-winner__gifts-slider-arrow--left"
                );
                const arrowSliderGasStationNNext = document.querySelector(
                    ".gas-station-winner__gifts-slider-arrow--right"
                );
                if (arrowSliderGasStationPrev) {
                    arrowSliderGasStationPrev.addEventListener("click", () => {
                        if (swiperObj) swiperObj.slidePrev();
                    });
                }
                if (arrowSliderGasStationNNext) {
                    arrowSliderGasStationNNext.addEventListener("click", () => {
                        if (swiperObj) swiperObj.slideNext();
                    });
                }
            });

            let swiperImages = swiperCarousel(".stock__slider-gallery", {
                slidesPerView: 3,
                centeredSlides: true,
                loop: true,
                loopedSlides: 6,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    prevEl: ".stock__slider-arrow--left",
                    nextEl: ".stock__slider-arrow--right",
                },
            });

            let swiperTitles = swiperCarousel(".stock__slider-titles", {
                slidesPerView: 1,
                loop: true,
                effect: "fade",
                loopedSlides: 6,
                centeredSlides: true,
            });

            swiperImages.then((value) => {
                const objSwiperImages = value;

                swiperTitles.then((value) => {
                    const objSwiperTitles = value;

                    objSwiperImages.on(
                        "slideChangeTransitionStart",
                        function () {
                            let index_currentSlide =
                                objSwiperImages.activeIndex;
                            objSwiperTitles.slideTo(
                                index_currentSlide,
                                300,
                                false
                            );
                        }
                    );
                });
            });

            let swiperMagicSphereGifts = swiperCarousel(
                ".magic-sphere__gifts-slider",
                {
                    loop: false,
                    spaceBetween: 24,
                    autoplay: {
                        delay: 3000,
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                            allowSlideNext: true,
                            allowSlidePrev: true,
                            allowTouchMove: true,
                        },
                        1280: {
                            slidesPerView: 7,
                            allowSlideNext: false,
                            allowSlidePrev: false,
                            autoplay: false,
                            allowTouchMove: false,
                        },
                    },
                    navigation: {
                        prevEl: ".magic-sphere__gifts-slider-arrow--left",
                        nextEl: ".magic-sphere__gifts-slider-arrow--right",
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    });
});
