define([], function () {

    $(document).ready(function() {

        const $btnOpenMobFilter = $('.js-open-nav-filters'),
            $filters = $('.js-nav-filters'),
            $btnCloseMobFilters = $('.js-filters-close');

        $btnOpenMobFilter.on('click', function(e) {
            e.preventDefault();

            $filters.show(300);
        })

        $btnCloseMobFilters.on('click', function(e) {
            e.preventDefault();

            $filters.hide(300);
        })

    });

});
