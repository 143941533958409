define([], function () {
    const swiperCarousel = require("./../modules/swiper");

    $(document).ready(function () {
        if ($(".million-reasons__slider").length) {
            const sliderNode = document.querySelector(
                ".million-reasons__slider"
            );

            let reasonsSlider = swiperCarousel(sliderNode, {
                slidesPerView: 1.25,
                centeredSlides: true,
                spaceBetween: 0,
                loop: true,
                loopedSlides: 10,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    prevEl: ".million-reasons__slider-arrow--left",
                    nextEl: ".million-reasons__slider-arrow--right",
                },
                breakpoints: {
                    1000: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                },
            });
        }

        $(window).on("load scroll", function () {
            var parallaxElement = $(".parallax_number"),
                parallaxQuantity = parallaxElement.length;
            window.requestAnimationFrame(function () {
                for (var i = 0; i < parallaxQuantity; i++) {
                    var currentElement = parallaxElement.eq(i),
                        windowTop = $(window).scrollTop(),
                        elementTop = currentElement.offset().top,
                        elementHeight = currentElement.height(),
                        viewPortHeight =
                            window.innerHeight * 0.5 - elementHeight * 0.5,
                        scrolled = windowTop - elementTop + viewPortHeight;
                    currentElement.css({
                        transform:
                            "translate3d(0," + scrolled * -0.15 + "px, 0)",
                    });
                }
            });
        });
    });
});
