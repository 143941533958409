define([], function () {

    $(document).ready(function() {

        const
            $popupTable = $('.js-certificate-popup-table-info'),
            $popupClose = $('.js-certificate-popup-close');

        $(document).on('click', '.js-certificate-open-info', function(e) {
            e.preventDefault();

            $popupTable.find('.js-certificate-popup-content').html(
                $(this).parents('.js-certificate-details').find('.js-certificate-details-content').html()
            );

            $popupTable.show(300);
        });

        $popupClose.on('click', function(e) {
            e.preventDefault();
            $popupTable.hide(300);
        });

    });

});
