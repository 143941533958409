define([], function () {

    $(document).ready(function() {

        $('.js-upload-file').each(function() {

            let $input = $(this),
                $nameFile = $input.parent().find('.js-file-name');

            $input.on('change', function(element) {
                let fileName = '';
                if (element.target.value) {
                    fileName = element.target.value.split('\\').pop();
                    $input.closest('.form-group').addClass('active');
                    $nameFile.html(fileName);
                }
            });

        });

    });

});
