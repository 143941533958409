import "../shellpay/modules/AOS-animation";

// Accordion
$(document).ready(function () {
    const previewVideoElement = document.querySelector(
        ".ferrari-promo__video-preview"
    );
    const videoElement = document.querySelector("#ferrari-promo__video-tag");

    function autoplayBmwVideo() {
        try {
            previewVideoElement.style = "display: none;";
            videoElement.play();
            is_paused = true;
        } catch (err) {
            console.error(err);
        }
    }

    function isInViewport(element) {
        if (!element) return;

        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    $(window).scroll(function () {
        if (isInViewport(previewVideoElement)) {
            autoplayBmwVideo();
        }
    });
});
