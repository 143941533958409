define([], function () {

    $(document).ready(function() {

        let $basketSection = $('.js-basket-section'),
            $controlCheckbox = $('.js-checkbox-control-btn'),
            $controlledBtn = $('.js-controlled-btn'),
            $selectPriceType = $('.js-form-cart .js-price-type'),
            $counterInputs = $('.js-form-cart .js-counter-input'),
            $certificatesWrap = $('.js-certificate-list'),
            $certificates = $('.js-certificate-item'),
            $balls = $('.js-sum-balls'),
            $currency = $('.js-sum-currency'),
            $btnDeleteCertificate = $('.js-certificate-delete'),
            $certificatesCount = $('.js-certificates-count'),
            $headerCartCounter = $('.basket .product-count'),
            $pintsOnlyWarning = $('.js-cart-is-points-only-warning');

        $selectPriceType.on('change', function() {
            updateSumHtml($(this), $(this).find('option:selected').val());
        });

        $counterInputs.on('change', function() {
            updateSumHtml($(this), $(this).val());
        });

        // $btnDeleteCertificate.on('click', function(e) {
        //     e.preventDefault();
        //
        //     $(this).closest('.js-certificate-item').remove();
        //
        //     updateProductsLength();
        //     updateSumHtml();
        // });

        $controlCheckbox.on('change', function(e) {

            const isChecked = getCheckboxState($(this));

            if( isChecked ) {
                $controlledBtn.attr('disabled', false);
            } else {
                $controlledBtn.attr('disabled', true);
            }

        });

        // priceSum - сумма балов и валюты по всем сертификатам что находятся в корзине
        // lengthOnlyBalls - кольчество сертификатов, тип оплаты в которых - только баллы
        function getSum() {

            let priceSum = {
                    balls: 0,
                    currency: 0
                },
                lengthOnlyBalls = 0;

            if ($certificatesWrap.find('.js-certificate-item').length) {
                $certificatesWrap.find('.js-certificate-item').each(function() {

                    let _this = $(this),
                        $priceType = _this.find('.js-price-type'),
                        $counter = _this.find('.js-counter-input'),
                        priceTypeVal = $priceType.find('option:checked').val().split('+'),
                        counterVal = +$counter.val();

                    priceSum.balls += (priceTypeVal[0] ? priceTypeVal[0] : 0) * counterVal;
                    priceSum.currency += (priceTypeVal[1] ? priceTypeVal[1] : 0) * counterVal;

                    if (priceTypeVal.length <= 1)
                        lengthOnlyBalls += counterVal;

                });
            }

            return {priceSum, lengthOnlyBalls};

        }


        // Обновление html по сумме отплаты
        function updateSumHtml(element, value) {
            $('.main-content').addClass('loader');

            let data = {
                key: element.parents('.js-certificate-item').data('cart-key')
            };
            data[element.attr('name')] = value;

            $.ajax({
                method: 'post',
                url: '/cart/change',
                dataType: 'json',
                data,
                success: function(data){
                    $('.main-content').removeClass('loader');

                    $balls.text(data.points);
                    $currency.text(data.hryvnas);

                    if(data.has_points_only){
                        $pintsOnlyWarning.show();
                    }else{
                        $pintsOnlyWarning.hide();
                    }

                    updateProductsLength(data.count);
                }
            });
        }

        // Обновление количества товаров в корзине
        function updateProductsLength(productLength) {
            $headerCartCounter.text(productLength);
            // if (productLength > 0) {
                $basketSection.removeClass('empty');
                $certificatesCount.text(productLength);
            // } else {
            //     $certificatesCount.text(0);
            //     $basketSection.addClass('empty');
            // }
        }

        function getCheckboxState($checkbox) {
            return $checkbox.is(":checked");
        }

    });

});
