define([], function () {

    let counter = function($counter) {

        const _this = $counter,
            $counterMinus = _this.find('.js-counter-minus'),
            $counterPlus = _this.find('.js-counter-plus'),
            $counterSpacer = _this.find('.js-spacer'),
            $counterInput = _this.find('.js-counter-input');

        const defaultOptions = {
            minVal: 0,
            maxVal: 100,
        }

        checkState(+$counterInput.val());

        $counterMinus.on('click', function(e) {
            e.preventDefault();

            let valueNew = +$counterInput.val() - 1;

            if (valueNew < defaultOptions.minVal)
                return;

            $counterInput.val(valueNew).trigger('change');
            $counterSpacer.text(valueNew);
            checkState(valueNew);
        });

        $counterPlus.on('click', function(e) {
            e.preventDefault();

            let valueNew = +$counterInput.val() + 1;

            if (valueNew > defaultOptions.maxVal)
                return;

            $counterInput.val(valueNew).trigger('change');
            $counterSpacer.text(valueNew);
            checkState(valueNew);
        });

        $counterInput.on('keyup', function() {
            let value = +$counterInput.val();
            if (value < defaultOptions.minVal) {
                $counterInput.val(defaultOptions.minVal)
            } else if (value > defaultOptions.maxVal) {
                $counterInput.val(defaultOptions.maxVal)
            }
            $counterSpacer.text(value);
            checkState(value);
        });

        function checkState(value) {
            if (value === defaultOptions.minVal) {
                _this.addClass('disabled-counter');
                // $counterInput.attr("disabled", true);
                // $counterInput.prop("disabled", true);
            } else {
                _this.removeClass('disabled-counter');
                // $counterInput.attr("disabled", false);
                // $counterInput.prop("disabled", false);
            }
        }

    }

    $(document).ready(function() {

        $('.js-counter').each(function() {
            counter($(this));
        });

    });

});
