import axios from "axios";

axios.defaults.headers = {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
};

$(document).ready(function() {

    const $btnSendRegistration = $('.js-send-registration');

    let authMethod = 'phone';

    $('input[name="auth-method"]').on('change', function() {

        let value = $(this).val();

        $(this).closest('.form-switcher').find('[data-auth-method]').addClass('hidden');
        $(this).closest('.form-switcher').find('[data-auth-method="' + value + '"]').removeClass('hidden');

        authMethod = value;

    });

    $('[name="card-availability"]').on('change', function() {

        let value = $(this).val(),
            $cardNumInput = $('.js-reg-card-input');

        switch (value) {
            case '1':
                $cardNumInput.removeClass('hidden').find('input').prop("disabled", false);
                break;
            default:
                $cardNumInput.addClass('hidden').find('input').prop("disabled", true);
                break;
        }

    });

    $('.js-send-phone-card').on('click', function(e) {
        e.preventDefault();

        let $form = $(this).closest('form'),
            $inputPhone = $form.find('[name="phone"]'),
            $inputCard = $form.find('[name="card"]'),
            valPhone = $inputPhone.val(),
            valCard = $inputCard.val(),
            send = true;

        switch (authMethod) {
            case 'phone':
                if ( valPhone.length === 0 || valPhone.indexOf('_') !== -1 ) {
                    checkErrorValidateInput($inputPhone);
                    send = false;
                }
                break;
            case 'card':
                if ( valCard.length === 0 ) {
                    checkErrorValidateInput($inputCard);
                    send = false;
                }
                break;
            default:
                console.error('Input [name="auth-method"] not found!')
        }

        if (send) {
            $('.main-content').addClass('loader');
            $form.submit();
        } else {
            console.log(' --- ', 'no validation');
        }

    });

    $('.js-send-sms-code').on('click', function(e) {
        e.preventDefault();

        let $form = $(this).closest('form'),
            $inputCode = $form.find('[name="otp"]'),
            valCode = $inputCode.val(),
            send = true;

        if ( valCode.length === 0 ) {
            checkErrorValidateInput($inputCode);
            send = false;
        }

        if (send) {
            $form.submit();
        } else {
            console.log(' --- ', 'no validation');
        }

    });

    // $btnSendRegistration.on('click', function(e) {
    //     e.preventDefault();
    //
    //     let $form = $(this).closest('form'),
    //         $inputName = $form.find('[name="reg-name"]'),
    //         $inputSurname = $form.find('[name="reg-surname"]'),
    //         $inputBirth = $form.find('[name="reg-date-of-birth"]'),
    //         $inputEmail = $form.find('[name="reg-email"]'),
    //         $inputPhone = $form.find('[name="reg-phone"]'),
    //         $radioCardAvailability = $form.find('[name="reg-card-availability"]'),
    //         $inputCard = $form.find('[name="reg-card"]'),
    //         valName = $inputName.val(),
    //         valSurname = $inputSurname.val(),
    //         valBirth = $inputBirth.val(),
    //         valEmail = $inputEmail.val(),
    //         valPhone = $inputPhone.val(),
    //         valCardAvailability = $radioCardAvailability.filter(':checked').val(),
    //         valCard = $inputCard.val(),
    //         mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //         send = true;
    //
    //     if ( valName.length === 0 ) {
    //         checkErrorValidateInput($inputName);
    //         send = false;
    //     }
    //
    //     if ( valSurname.length === 0 ) {
    //         checkErrorValidateInput($inputSurname);
    //         send = false;
    //     }
    //
    //     if ( valBirth.length === 0 || valBirth.indexOf('_') !== -1 ) {
    //         checkErrorValidateInput($inputBirth);
    //         send = false;
    //     }
    //
    //     // if ( valEmail.length < 1 && !mailRegex.test(valEmail) ) {
    //     //     checkErrorValidateInput($inputEmail);
    //     //     isValid = false;
    //     // }
    //
    //     if ( valPhone.length === 0 || valPhone.indexOf('_') !== -1 ) {
    //         checkErrorValidateInput($inputPhone);
    //         send = false;
    //     }
    //
    //     if ( valCardAvailability === 'yes' ) {
    //         if ( valCard.length === 0 ) {
    //             checkErrorValidateInput($inputCard);
    //             send = false;
    //         }
    //     }
    //
    //     if (send) {
    //         console.log(' --- ', 'go ajax');
    //     } else {
    //         console.log(' --- ', 'no validation');
    //     }
    //
    // });

    if($('.js-otp-resend').length) {
        let setBlocking = function () {
            $('.js-otp-resend').addClass('disabled');
            setTimeout(function () {
                $('.js-otp-resend').removeClass('disabled');
            }, 60000);
        };

        setBlocking();

        $('.js-otp-resend').on('click', function (e) {
            e.preventDefault();

            if(!$(this).hasClass('disabled')) {
                axios.post($(this).attr('href'))
                    .then(function (response) {
                        setBlocking();
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    }

    $('.js-check-agreement').on('change', function() {
        if ( $(this).prop('checked') ) {
            $btnSendRegistration.prop("disabled", false);
        } else {
            $btnSendRegistration.prop("disabled", true);
        }
    })

    function checkErrorValidateInput(elem) {
        elem.css({"border-color": "#e34545"});
        setTimeout(function() {
            elem.attr("style", "");
        }, 3000);
    }

});
