define([], function () {

    const initAutocomplete = require('./../modules/autocomplete');

    $(document).ready(function() {

        var availableTags = [
            "ActionScript",
            "AppleScript",
            "Asp",
            "BASIC",
            "C",
            "C++",
            "Clojure",
            "COBOL",
            "ColdFusion",
            "Erlang",
            "Fortran",
            "Groovy",
            "Haskell",
            "Java",
            "JavaScript",
            "Lisp",
            "Perl",
            "PHP",
            "Python",
            "Pyt11111",
            "Pyt222222",
            "Ruby",
            "Scala",
            "Scheme"
        ];

        $('.js-address-autocomplete').each(function() {

            initAutocomplete($(this), {
                minLength: 2,
                source: availableTags,
                // source: function( request, response ) {
                //     $.ajax({
                //         dataType: "json",
                //         type : 'Get',
                //         url: 'yourURL',
                //         data: {  term: request.term },
                //         success: function(data) {
                //             response(data);
                //         },
                //         error: function(data) {
                //             console.error(data);
                //         }
                //     });
                // },
            });

        });

    });

});
