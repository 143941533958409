(function ($) {

    const inputStyle = function($el){

        let label;

        if( $el.attr('id') == '' || $("label[for='" + $el.attr('id') + "']").length == 0 ) {
            label = $el.parent().find('label').first();
        } else {
            label = $("label[for='" + $el.attr('id') + "']");
        }


        if (!$el.parents('.input-inset-label').length) {
            $el.wrap('<span class="input-inset-label"></span>');
            $el.parents('.input-v2').append(label);
        }


        if ($el.attr('disabled')) {
            $el.parent().addClass('disabled');
        }

        $el.on('focusin', function (e) {
            $el.parents('.input-inset-label').addClass('active');
            $el.parents('.input-inset-label').addClass('in-focus');
        });

        $el.on('focusout', function (e) {
            if ($el.val() === '') {
                $el.parents('.input-inset-label').removeClass('active');
            }
            $el.parents('.input-inset-label').removeClass('in-focus');
        });

        $el.on('change', function (e) {
            if ($el.val() === '') {
                $el.parents('.input-inset-label').removeClass('active');
            } else {
                $el.parents('.input-inset-label').addClass('active');
            }
        });

        if ($el.val() !== '') {
            $el.parents('.input-inset-label').addClass('active');
        }

    }

    $(document).ready(function() {

        $('.js-input-style').each(function() {
            inputStyle($(this));
        });

    });

}(jQuery));
